//=============================
//HOME WINERY SECTION
//=============================

.homeWinerySection {
  background-color: $background-color;
  padding: 9rem 0;
  position: relative;
  overflow: hidden;

  // Subtle background pattern
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(
      circle at 25% 75%,
      rgba($color-primary-blue, 0.03) 1%,
      transparent 3%
    );
    background-size: 40px 40px;
    opacity: 0.6;
    z-index: 0;
  }

  &__container {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  &__titleContainer {
    text-align: center;
    margin-bottom: 5rem;

    @include query(max-w-768) {
      margin-bottom: 3.5rem;
    }
  }

  &__title {
    font-size: clamp(3rem, 5vw, 4rem);
    color: $color-primary-blue;
    font-weight: 700;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 4px;
      background-color: $color-primary-yellow;
      border-radius: 2px;
    }
  }

  &__subtitle {
    font-size: 1.8rem;
    color: rgba($color-primary-blue, 0.8);
    max-width: 600px;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 1.6;
  }

  @include query(max-w-1024) {
    padding: 6rem 0 3rem 0;
  }

  &__loadingCards {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__cardsContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 4rem;
    box-sizing: border-box;
    margin-bottom: 4rem;

    @include query(max-w-1024) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 2.5rem;
    }

    @include query(max-w-600) {
      grid-template-columns: 1fr;
      grid-gap: 3rem;
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__viewAll {
    text-align: center;
    margin-top: 2rem;
  }

  &__viewAllLink {
    display: inline-flex;
    align-items: center;
    color: $color-primary-yellow;
    background-color: $color-primary-blue;
    font-size: clamp(1.6rem, 2vw, 1.8rem);
    font-weight: 600;
    transition: all 0.3s ease;
    padding: 1rem 2rem;
    border: 2px solid $color-primary-blue;
    border-radius: 1rem;
    text-decoration: none;

    &:hover {
      background-color: $color-primary-yellow;
      color: $color-primary-blue;
      border: 2px solid $color-primary-yellow;
      transform: translateY(-2px);

      .homeWinerySection__arrow {
        transform: translateX(5px);
      }
    }
  }

  &__arrow {
    margin-left: 1rem;
    transition: transform 0.3s ease;
  }
}

.badge {
  background-color: $color-primary-blue !important;
  color: $color-primary-yellow;
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  margin: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

//=============================
//HOME WINERY SECTION CARD
//=============================

.homeWinerySectionCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);

  a {
    align-self: flex-end;
  }

  &__border {
    display: grid;
    height: 100%;
  }

  &__title {
    font-family: "Lato", sans-serif;
    font-size: 2.4rem;
    color: $color-primary-blue;
    font-weight: 700;
    padding: 2rem;
    margin: 0;
    text-align: left;

    @include query(max-w-1024) {
      padding: 1.5rem;
    }
  }

  &__imgContainerLoading {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }

  &__imgContainer {
    position: relative;
    padding-top: 66.67%; // 3:2 aspect ratio
    width: 100%;
    height: 200px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  // Position the loading spinner in the center of the image container
  .loading--contained {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__body {
    padding: 2.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0.5rem;
    gap: 0.4rem;
    font-size: 1.6rem;
    color: rgba($color-primary-blue, 0.8);
    font-size: clamp(1.2rem, 1.8vw, 1.4rem);

    div {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }
    }

    // Add region tag styling
    .region-tag {
      background-color: $color-primary-blue;
      height: fit-content;
      color: $color-primary-yellow;
      padding: 0.4rem 0.8rem;
      border-radius: 0.4rem;
      display: inline-block;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  &__status {
    display: inline-block;
    padding: 0.6rem 1.2rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    font-weight: 600;

    &.open {
      background-color: rgba(#2ecc71, 0.15);
      color: #2ecc71;
    }

    &.closed {
      background-color: rgba($color-primary-red-dark, 0.15);
      color: $color-primary-red-dark;
    }
  }

  &__hours {
    color: rgba($color-primary-blue, 0.8);
    font-size: 1.4rem;
    font-weight: 500;
  }

  &__contactLinks {
    display: flex;
    gap: 1.6rem;
    padding: 1rem 0;

    a {
      color: rgba($color-primary-blue, 0.7);
      font-size: 1.8rem;
    }
  }

  &__amenities {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.7rem;
    padding: 0.3rem 0 1rem 0;
    margin-bottom: 1rem;
  }

  &__amenity {
    color: rgba($color-primary-blue, 0.8);
    border-radius: 0.6rem;
    font-size: 1.4rem;
    width: 24px;
    height: 24px;
  }

  &__amenityIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    display: block;
  }

  &__amenityMore {
    background-color: rgba($color-primary-blue, 0.05);
    color: rgba($color-primary-blue, 0.6);
    padding: 0.6rem 1.2rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    cursor: help;
  }

  &__featuredCount {
    color: rgba($color-primary-blue, 0.7);
    font-size: 1.4rem;
    padding: 0.5rem 0;
    font-weight: 500;

    &::before {
      content: "🍷";
      margin-right: 0.8rem;
    }
  }

  &__hoursContainer {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 0.8rem 0;
    margin: 0.5rem 0;
  }

  &__button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary-blue;
    color: white;
    padding: 1.2rem 2.4rem;
    border-radius: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken($color-primary-blue, 10%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba($color-primary-blue, 0.2);
    }
  }

  &__cornerTag {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-primary-blue;
    color: white;
    padding: 0.8rem 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    z-index: 1;
    border-radius: 0 1.2rem 0 1.2rem;
    transition: all 0.3s ease;
    width: auto;

    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);

    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &::before {
        content: "✓";
        font-size: 1.2rem;
      }
    }
  }
}

//=============================
//HOME WINERY SPOT
//=============================

.winerySpot {
  padding: 6rem 0;

  &__titleContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem 4rem;
  }

  &__title {
    font-size: clamp(3rem, 5vw, 4.5rem);
    color: $color-primary-blue;
    font-weight: 700;
    text-align: center;
  }

  &__spotContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    gap: 4rem;
    align-items: center;

    @include query(max-w-1024) {
      flex-direction: column;
      gap: 3rem;
    }
  }

  &__imgContainer {
    flex: 1;
    border-radius: 1.2rem;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    height: 200px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease, opacity 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__card {
    flex: 1;
    padding: 2rem;
  }

  &__cardTitle {
    font-size: clamp(2.4rem, 4vw, 3.2rem);
    color: $color-primary-blue;
    margin-bottom: 1.5rem;
    font-weight: 700;
  }

  &__cardLocation {
    font-size: 1.8rem;
    color: rgba($color-primary-blue, 0.8);
    line-height: 1.6;
  }
}

// Add tooltip styles
.tooltip {
  font-size: 1.2rem !important;
  padding: 0.8rem 1.2rem !important;
  background-color: rgba($color-primary-blue, 0.95) !important;
  border-radius: 0.6rem !important;
  max-width: 200px !important;

  &.show {
    opacity: 1 !important;
  }
}

.tooltip-inner {
  padding: 0.8rem 1.2rem !important;
  color: white !important;
  text-align: center !important;
  background-color: rgba($color-primary-blue, 0.95) !important;
  border-radius: 0.6rem !important;
}
