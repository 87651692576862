.wineryIndex {
  &__container {
    width: 100%;
    margin: 0 auto;
    padding: 2em 0;
    display: flex;
    flex-direction: row;
  }

  &__loadingMore {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  &__noResults {
    width: 100%;
    text-align: center;
    padding: 2rem;
    font-size: 1.6rem;
    color: #666;
  }

  &__mobile-select {
    display: none;
    position: relative;

    @include query(max-w-1124) {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: 0 auto 2em;

      h3 {
        margin-bottom: 0.8em;
        color: $color-primary-blue;
        font-size: 1.4em;
        text-align: left;
        font-family: Lora, serif;
        font-weight: 600;
        letter-spacing: 0.02em;
      }

      &::after {
        content: "▼";
        position: absolute;
        right: 15px;
        top: 65%;
        transform: translateY(-50%) scale(0.8);
        color: $color-primary-blue;
        pointer-events: none;
        font-size: 0.9em;
        transition: transform 0.2s ease;
      }

      select {
        width: 100%;
        padding: 14px 45px 14px 20px;
        font-size: 1.2em;
        font-family: Lora, serif;
        color: $color-primary-blue;
        background-color: white;
        border: 2px solid rgba($color-primary-blue, 0.8);
        border-radius: 12px;
        appearance: none;
        cursor: pointer;
        transition: all 0.25s ease;
        box-shadow: 0 2px 8px rgba($color-primary-blue, 0.08);

        &:hover {
          background-color: rgba($color-primary-blue, 0.03);
          border-color: $color-primary-blue;
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba($color-primary-blue, 0.12);
        }

        &:focus {
          outline: none;
          border-color: $color-primary-blue;
          background-color: white;
          box-shadow: 0 0 0 3px rgba($color-primary-blue, 0.15);
          transform: translateY(-1px);
        }

        option {
          padding: 16px;
          font-size: 1.1em;
          background-color: white;
          color: $color-primary-blue;

          &:checked {
            background-color: rgba($color-primary-blue, 0.1);
          }

          &:disabled {
            color: rgba($color-primary-blue, 0.4);
            font-style: italic;
            background: rgba($color-primary-blue, 0.02);
          }
        }
      }

      &:focus-within {
        &::after {
          transform: translateY(-50%) scale(0.8) rotate(180deg);
        }
      }
    }
  }

  &__marker {
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  &__rowsContainer {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 1em 4em 3em;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
  }

  &__rows {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    gap: 2em;
    padding: 1em 4em 3em;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;

    @include query(max-w-1124) {
      grid-template-columns: repeat(2, minmax(auto, 1fr));

      padding: 1em 2em;
      gap: 1.5em;
    }

    @include query(max-w-600) {
      grid-template-columns: repeat(1, minmax(auto, 1fr));

      padding: 2em 1em;
      width: 85%;
    }
  }

  &__sider {
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 250px;
    padding: 0 0 0 3em;
    justify-content: flex-start;
    align-items: flex-start;

    @include query(max-w-1124) {
      display: none;
    }

    .nav-pills .nav-link.active,
    .nav-pills .nav-link.active button {
      background-color: $color-primary-blue;
      color: $color-primary-yellow;
      width: 100%;
      text-align: start;
      border-radius: 12px;
      transition: all 0.3s ease;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    gap: 0.5em;

    &.sticky {
      position: fixed;
      align-self: flex-start;
      top: 125px;
      width: auto;
    }

    button {
      font-size: 1.5em;
      font-family: Lora, serif;
      font-weight: 500;
      padding: 0.8em 1.2em;
      transition: all 0.25s ease;
      white-space: nowrap;
      width: 100%;
      text-align: left;

      &:hover {
        transform: translateX(5px);
        color: $color-primary-blue;
      }
    }
  }

  &__sidebarButton {
    display: flex;
    justify-content: flex-start;
    background: transparent;
    border: none;
    min-width: 200px;

    &:active,
    &:focus {
      border: none;
      border-radius: 12px;
      box-shadow: none;
      outline: none;
      background-color: rgba($color-primary-blue, 0.1);
    }
  }

  &__navItem {
    padding: 0.5rem 1rem;
    width: 100%;

    .nav-link {
      padding: 0;
      transition: all 0.25s ease;
      border-radius: 12px;
      overflow: hidden;

      &:hover {
        opacity: 0.9;
        background-color: rgba($color-primary-blue, 0.05);
      }

      &.active {
        button {
          color: $color-primary-yellow;
          transform: none;

          &:hover {
            color: $color-primary-yellow;
          }
        }
      }
    }

    // Specific styling for each state to ensure consistent width
    &:nth-child(1) button {
      // All
      min-width: 100px;
    }
    &:nth-child(2) button {
      // Baja California
      min-width: 180px;
    }
    &:nth-child(3) button {
      // Coahuila
      min-width: 140px;
    }
    &:nth-child(4) button {
      // Guanajuato
      min-width: 160px;
    }
    &:nth-child(5) button {
      // Querétaro
      min-width: 150px;
    }
  }

  &__viewToggleContainer {
    flex: 1;
    gap: 1rem;
    margin: 2rem 0;
  }

  &__viewToggle {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 2rem 0;
  }

  &__viewButton {
    padding: 0.8rem 1.6rem;
    font-size: 1.4rem;
    border-radius: 8px;
    background: transparent;
    color: $color-primary-blue;
    transition: all 0.3s ease;

    &:hover {
      background: $color-primary-red-dark;
    }

    &.active {
      background: $color-primary-blue !important;
      color: white;
    }
  }

  &__map {
    width: 95%;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  &__mapMarker {
    position: relative;
    cursor: pointer;
  }

  &__markerContent {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 0.8rem 1.2rem;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    font-size: 1.4rem;
    white-space: nowrap;
    color: $color-primary-blue;
    font-weight: 500;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 8px solid transparent;
      border-top-color: white;
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
